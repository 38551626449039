import cxPush from '../helpers/cxPush';
import getValueFromObjectList from '../helpers/getValueFromObjectList';

const intentEvents = () => {
  const intentParams = INTENT;
  const brand = BRAND;
  const btns = intentParams.targets;
  const eventType = intentParams.eventType;
  const properties = {};
  const el = [];
  let property;
  const list = [];
  const listName = [];
  let temp;

  // set all required data in properties object
  // prepare arrays in 'list' to split before cxPush
  const prepareValues = () => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(intentParams.data)) {
      temp = getValueFromObjectList(intentParams.data[key]);
      // value = array with 1 element, object with name, type
      if (temp) {
        if (temp.value instanceof Array) {
          list.push(temp.value);
          listName.push(temp.property);
        } else if (!(temp.value instanceof Object)) {
          properties[`${brand}_${temp.property}`] = temp.value;
        }
      }
    }
  };

  // get buttons based on selector
  btns.forEach(element => {
    el.push(document.querySelectorAll(element));
  });

  // splits arrays up into seperate cxPushes (Cxense doesn't accept arrays)
  const sendIntentEvent = () => {
    prepareValues();
    if (list) {
      const iterations = list.reduce(
        (p, c, i, a) => (a[p].length > c.length ? p : i),
        0
      );
      for (let i = 0; i <= iterations; i += 1) {
        property = { ...properties };
        for (let j = 0; j < list.length; j += 1) {
          if (list[j][i]) {
            const keyName = `${brand}_${listName[j]}`;
            const tag = { [keyName]: list[j][i] };
            property = { ...property, ...tag };
          }
        }
        cxPush('sendEvent', eventType, property, 'intent');
      }
    }
  };

  // assign click eventlistener to buttons
  el.forEach(element => {
    for (let i = 0; i < element.length; i += 1) {
      if (element[i]) {
        element[i].addEventListener('click', sendIntentEvent);
      }
    }
  });
};

export default intentEvents;
