/**
 * @module helpers/cxPush
 * Pushes data to cX
 */

import log from '../helpers/logger';

const cxPush = (event, type, data, origin, options = {}) => {
  window.cX = window.cX || {};
  window.cX.callQueue = window.cX.callQueue || [];
  window.cX.callQueue.push([
    event,
    type,
    data,
    {
      origin: `${options.prefix || PREFIX}-${origin}`,
      persistedQueryId: options.pqid || CXENSE_QUERYID_PUSH,
    },
  ]);
  log(`push ${type} to ${options.prefix || PREFIX}-${origin}`);
};

export default cxPush;
